import './App.css';
import TopLevel from './TopLevel';
import React, { useState } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import Portis from "@portis/web3";

const providerOptions = {
  portis: {
    package: Portis, // required
    options: {
      id: "8e23465f-c9a7-410a-92df-18b2e3d1c38f",
      network: "maticMumbai"
    }
  }
};

const web3Modal = new Web3Modal({
  network: "ropsten",
  cacheProvider: true, // optional
  providerOptions  // required
});

function App () {

  const [account, setAccount] = useState('Not Connected');

  const [web3, setWeb3] = useState(null);

  async function getWeb3(){
    //let Provider = await web3Modal.connect(); //ethereum
    //let w3 = new Web3(Provider); //ethereum
    
    let w3 = new Web3('http://127.0.0.1:8545/'); //local

    return (w3);
  }

  async function getAccounts(w3){
    let accounts = await w3.eth.getAccounts();
    return (accounts);
  }

  async function handleClick() {
    let w3 = await getWeb3()
    setWeb3(w3)

    let accounts = await getAccounts(w3)
    setAccount(accounts[0]); //allow user to define which account
  }

  return (
    <div className="App" id="app">
      <div className="showPortisBtn">
        {/* <div style={{
          display: 'inline-block',
          width: '80%' 
          }}>
          Wallet Address: {account.toLowerCase()}
        </div> */}
        {/* <button style={{
          display: 'inline-block',
          width: '20%'   
          }} 
          type="primary" onClick={() => handleClick()
          }>
            Connect
        </button> */}
        {/* <button style={{
          display: 'inline-block',
          width: '20%'   
          }} 
          type="primary" onClick={() => test()
          }>
            Test
        </button> */}
      </div>
      <TopLevel
        connectClick = {handleClick}        
        web3 = {web3}
        account = {account}
        // provider = {provider}
      />
    </div>
  );
}

export default App;
