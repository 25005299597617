import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import dateFormat from "dateformat";
import contract from './contracts/Time.json';
import './TimeModal.css';
import pack from 'box-circle-packer'
import { faL } from '@fortawesome/free-solid-svg-icons';


const timeEpochToDate = (time) => {
    let date = dateFormat(time, "dddd, mmmm dS, yyyy, h:MM TT")
    // console.log(time)
    return date
}

const TimeModal = (params) => {

    const [disabledBuy, setDisabledBuy] = useState(true);
    const [message, setMessage] = useState("");
    const [color, setColor] = useState("");
    const [image, setImage] = useState("");
    const [audio, setAudio] = useState("");
    const [bid, setBid] = useState(0);
    const [sellPrice, setSellPrice] = useState(0);
    const [forSalePrice, setForSalePrice] = useState(0);
    const [timeIsPast, setTimeIsPast] = useState(false);
    const [web3Connected, setWeb3Connected] = useState(false);
    const [timeIsAccountOwned, setTimeIsAccountOwned] = useState(false);
    const [timeIsOwned, setTimeIsOwned] = useState(false);
    const [timeIsForSale, setTimeIsForSale] = useState(false);
    const [timeIsModifiable, setTimeIsModifiable] = useState(false);
    const [owner, setOwner] = useState("Connect Wallet to Check Availability");
    var Aud = new Audio()
    Aud.id = "modal_audio"

    let radiuses = [
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10
    ]
    let width = 300
    let height = 300
    let spacingFactor = Math.random()

    const packedCircles = pack(radiuses, width, height, spacingFactor)

    async function getOwnership() {
        let ownerOfToken = await params.nftContract.methods.ownerOf(params.focusedMinute).call(function (err, own) {
            if (err) {
                console.log("ERROR reading URI")
            } else if (! err) {
                console.log(own)
                setOwner("Owned by: " + own)
                // return own
            }
        })
    }

    async function checkIfTimeOwned(tokenId){
      var IdIsOwned = await params.nftContract.methods.exists(tokenId).call()
      console.log(IdIsOwned)
      return IdIsOwned
    }

    async function checkIfTimeForSale(tokenId){
      var IdIsForSale = await params.nftContract.methods.tokenIdForSale(tokenId).call()
      return IdIsForSale
    }

    async function checkHighestBid(tokenId){
      var highestBid = await params.nftContract.methods.highestBids(tokenId).call()
      return highestBid
    }

    async function checkForSalePrice(tokenId){
      var forSalePrice = await params.nftContract.methods.tokenIdToValue(tokenId).call()
      return forSalePrice
    }

    useEffect(() => {
      console.log(timeIsPast)
      if (params.modalIsOpen == true){
        //modal is open
        if (params.topParams.web3 !== null) {
          setWeb3Connected(true)
          if (params.focusedMinute <= params.minutes ){
            //focused time is in the past and not biddable
            setTimeIsPast(true)
            console.log(timeIsPast)
            if (params.ownedPixels.includes(params.focusedMinute)){
              //focused time is owned by connected account
              setTimeIsAccountOwned(true)
              setTimeIsOwned(true)
              checkIfTimeForSale(params.focusedMinute)
              .then((forSale)=>{
                console.log("This time is for sale: "+forSale)
                setTimeIsForSale(forSale)
                if (forSale == true){
                  console.log('Checking for sale price')
                  checkForSalePrice(params.focusedMinute).then((price) => {
                    console.log(price)
                    setForSalePrice(price);
                  })
                }
              })
            }
            else{
              setTimeIsAccountOwned(false)
              //use does not own TIME, but check if anyone owns it
              console.log(params.allPixelDetails[params.focusedMinute])
              checkIfTimeOwned(params.focusedMinute)
                .then((owned) => {
                  console.log(owned)
                  if(owned){
                    console.log("time is owned")
                    setTimeIsOwned(true)
                    checkIfTimeForSale(params.focusedMinute)
                    .then((forSale)=>{
                      console.log("This time is for sale: "+forSale)
                      setTimeIsForSale(forSale)
                      if (forSale == true){
                        console.log('Checking for sale price')
                        checkForSalePrice(params.focusedMinute).then((price) => {
                          console.log(price)
                          setForSalePrice(price);
                        })
                      }
                    })
                  }
                  else{
                    console.log('Time is not Owned')
                    setTimeIsOwned(false)
                    setTimeIsForSale(false)
                    setForSalePrice(0)
                  }
                })
            }
          }
          else {
            //focused time is in the future and biddable
            setTimeIsPast(false)
            //check what highest bid is
            checkHighestBid(params.focusedMinute)
              .then((highestBid)=>{
                if (highestBid>0){
                  setBid(highestBid)
                }
                else{
                  setBid(params.currentMintPrice)
                }
              })
          }

          // generate call data to get owner of token #1
          // const callData = params.nftContract.methods["ownerOf"](params.focusedMinute).encodeABI();
          // call the blockchain to get the owner of token #1
          // console.log(params.allPixelDetails[params.focusedMinute])
          if (params.allPixelDetails[params.focusedMinute] !== 'undefined') {
              getOwnership()
          }

          if (params.allPixelDetails[params.focusedMinute] !== undefined) {
            console.log('owned')
            // document.getElementById("display_"+params.focusedMinute).innerHTML = params.focusedMinute.message
            if (params.allPixelDetails[params.focusedMinute].details.message !== undefined) {
                setMessage(params.allPixelDetails[params.focusedMinute].details.message)
            }
            if (params.allPixelDetails[params.focusedMinute].details.color !== undefined) {
                setColor(params.allPixelDetails[params.focusedMinute].details.color)
            }
            if (params.allPixelDetails[params.focusedMinute].details.image !== undefined) {
                setImage(params.allPixelDetails[params.focusedMinute].details.image)
            }
            if (params.allPixelDetails[params.focusedMinute].details.audio !== undefined && params.modalIsOpen == true) {
                setAudio(params.allPixelDetails[params.focusedMinute].details.audio)
            }
          } 
        }
        else{
          setWeb3Connected(false);
          if (params.allPixelDetails[params.focusedMinute] !== undefined) {
            console.log('owned')
            // document.getElementById("display_"+params.focusedMinute).innerHTML = params.focusedMinute.message
            if (params.allPixelDetails[params.focusedMinute].details.message !== undefined) {
                setMessage(params.allPixelDetails[params.focusedMinute].details.message)
            }
            if (params.allPixelDetails[params.focusedMinute].details.color !== undefined) {
                setColor(params.allPixelDetails[params.focusedMinute].details.color)
            }
            if (params.allPixelDetails[params.focusedMinute].details.image !== undefined) {
                setImage(params.allPixelDetails[params.focusedMinute].details.image)
            }
            if (params.allPixelDetails[params.focusedMinute].details.audio !== undefined && params.modalIsOpen == true) {
                setAudio(params.allPixelDetails[params.focusedMinute].details.audio)
            }
          } 
        }
      }
      else { //modal is closed
            setMessage("");
            setColor("");
            setImage("");
            setAudio("");
            setOwner("Available");
            setSellPrice(0);
            setBid(0);
        }
    }, [params.modalIsOpen]);

    useEffect(() => {
        if (audio !== "") {
            console.log('found audio file')
            // Aud = new Audio(audio)
            Aud.src = audio
            Aud.play()
        }
        // if(audio == "" && Aud !== null){
        // console.log('no audio file')
        // // Aud = new Audio(audio)
        // Aud.stop()
        // }
    }, [audio])

    // const playSound = (aud, play) => {
    // console.log(audio)
    // var a = new Audio(aud);
    // if (play == true){
    //     a.play();
    // }else if (play == false){
    //     a.pause();
    // }else return
    // }

    const setModalIsOpenToFalse = () => {

        console.log(packedCircles)

        console.log(Aud)
        Aud.pause()

        params.setModalIsOpen(false)
        setMessage("")
        setColor("")
        setImage("")
        setAudio("")
        // setOwner("Available")
        // stop playing audio on close

        // Set the Buy Price
        // updateCurrentPrice()
    }

    const sellTime = () => {
        console.log('placing NFT for sale')
        if (params.topParams.web3 !== null) {
            let intID = params.focusedMinute
            sellNFT(intID, sellPrice)
        } else {
            alert('Please connect your wallet to purchase/bid on a time pixel')
        }
    }

    const bidTime = () => {
        console.log('bidding on NFT')
        if (params.topParams.web3 !== null) {
            let intID = params.focusedMinute
            bidNFT(bid, intID)
        } else {
            alert('Please connect your wallet to purchase/bid on a time pixel')
        }
    }

    const buyTime = () => {
      console.log('buying NFT from seller')
      if (params.topParams.web3 !== null) {
          let intID = params.focusedMinute
          buyNFT(intID)
      } else {
          alert('Please connect your wallet to purchase/bid on a time pixel')
      }
  }

    const mintTime = () => {
        console.log('buying nft')
        if (params.topParams.web3 !== null) {
            let tokenURI = '{"color":"' + {
                color
            }.color + '","message":"' + {
                message
            }.message + '","image":"' + {
                image
            }.image + '","audio":"' + {
                audio
            }.audio + '"}';
            let intID = params.focusedMinute
            mintNFT(tokenURI, intID)
        } else {
            alert('Please connect your wallet to purchase/bid on a time pixel')
        }
    }

    const modifyTime = () => {
        console.log('modifying URI of nft')
        if (params.topParams.web3 !== null) {
            let newTokenURI = '{"color":"' + {
                color
            }.color + '","message":"' + {
                message
            }.message + '","image":"' + {
                image
            }.image + '","audio":"' + {
                audio
            }.audio + '"}';
            let intID = params.focusedMinute
            modifyNFT(newTokenURI, intID)
        } else {
            alert('Please connect your wallet to purchase/bid on a time pixel')
        }
    }

    async function modifyNFT(newTokenURI, tokenId) {
        if (params.topParams.web3 !== null) {
            console.log('Have necessary web3 params to modify NFT')
            let PUBLIC_KEY = params.topParams.account
            const nonce = await params.topParams.web3.eth.getTransactionCount(PUBLIC_KEY, 'latest'); // get latest nonce
            console.log(nonce)
            console.log(owner)
            console.log(newTokenURI)
            console.log(tokenId)
            const txParams = {
                from: PUBLIC_KEY,
                to: params.contractAddress,
                nonce: nonce,
                gas: 500000,
                data: params.nftContract.methods.modifyURI(tokenId, newTokenURI).encodeABI()
            }
            params.topParams.web3.eth.sendTransaction(txParams, function (err, transactionHash) {
                if (! err) 
                    console.log("Success! View at: https://ropsten.etherscan.io/tx/" + transactionHash);
                
                alert("Transaction Successful! View this transaction on the blockchain: https://ropsten.etherscan.io/tx/" + transactionHash)
                // add ID to cache list
                let newDetailList = params.allPixelDetails
                newDetailList[tokenId] = {
                    "ID": String(tokenId),
                    "details": JSON.parse(newTokenURI)
                }
                console.log(newDetailList)
                params.setAllPixelDetails(newDetailList)
                console.log(params.allPixelDetails)
                console.log(params.allPixelDetails)
                params.writeServerCache()
            });
        }
    }

    async function mintNFT(tokenURI, unixTime) {
        if (params.topParams.web3 !== null) {
            console.log('Have necessary web3 params to purchase NFT')
            let PUBLIC_KEY = params.topParams.account
            const nonce = await params.topParams.web3.eth.getTransactionCount(PUBLIC_KEY, 'latest'); // get latest nonce
            const currentPrice = await params.nftContract.methods.currentPrice().call(); // get latest mint price
            const txParams = {
                from: PUBLIC_KEY,
                to: params.contractAddress,
                value: currentPrice,
                nonce: nonce,
                gas: 500000,
                data: params.nftContract.methods.mintNFT(PUBLIC_KEY, tokenURI, unixTime).encodeABI()
            }
            params.topParams.web3.eth.sendTransaction(txParams, function (err, transactionHash) {
                if (! err) 
                    console.log("Success! View at: https://ropsten.etherscan.io/tx/" + transactionHash);
                
                alert("Transaction Successful! View this transaction on the blockchain: https://ropsten.etherscan.io/tx/" + transactionHash)
                // add ID to cache list
                let newDetailList = params.allPixelDetails
                newDetailList[unixTime] = {
                    "ID": String(unixTime),
                    "details": JSON.parse(tokenURI)
                }
                console.log(newDetailList)
                params.setAllPixelDetails(newDetailList)
                console.log(params.allPixelDetails)
                params.writeServerCache().then(params.checkOwnership(params.nftContract))

            });
        }
    }

    async function bidNFT(bidPrice, unixTime) {
        if (params.topParams.web3 !== null) {
            console.log('Have necessary web3 params to bid on NFT')
            let PUBLIC_KEY = params.topParams.account
            const nonce = await params.topParams.web3.eth.getTransactionCount(PUBLIC_KEY, 'latest'); // get latest nonce
            const txParams = {
                from: PUBLIC_KEY,
                to: params.contractAddress,
                value: bidPrice,
                nonce: nonce,
                gas: 500000,
                data: params.nftContract.methods.bid(unixTime).encodeABI()
            }
            params.topParams.web3.eth.sendTransaction(txParams, function (err, transactionHash) {
                if (! err) 
                    console.log("Success! View at: https://ropsten.etherscan.io/tx/" + transactionHash);
                
                alert("Transaction Successful! View this transaction on the blockchain: https://ropsten.etherscan.io/tx/" + transactionHash)
            });
        }
    }

    async function sellNFT(tokenId, sellPrice) {
        if (params.topParams.web3 !== null) {
            console.log('Have necessary web3 params to bid on NFT')
            let PUBLIC_KEY = params.topParams.account
            const nonce = await params.topParams.web3.eth.getTransactionCount(PUBLIC_KEY, 'latest'); // get latest nonce
            const txParams = {
                from: PUBLIC_KEY,
                to: params.contractAddress,
                nonce: nonce,
                gas: 500000,
                data: params.nftContract.methods.setForSale(tokenId, sellPrice).encodeABI()
            }
            params.topParams.web3.eth.sendTransaction(txParams, function (err, transactionHash) {
                if (! err) 
                    console.log("Success! View at: https://ropsten.etherscan.io/tx/" + transactionHash);
                
                alert("Transaction Successful! View this transaction on the blockchain: https://ropsten.etherscan.io/tx/" + transactionHash)
            });
        }
    }

    async function buyNFT(tokenId) {
        if (params.topParams.web3 !== null) {
            console.log('Have necessary web3 params to bid on NFT')
            let PUBLIC_KEY = params.topParams.account
            const nonce = await params.topParams.web3.eth.getTransactionCount(PUBLIC_KEY, 'latest'); // get latest nonce
            var IdIsForSale = await params.nftContract.methods.tokenIdForSale(tokenId).call()
            if (IdIsForSale) {
                const currentSalePrice = await params.nftContract.methods.tokenIdToValue(tokenId).call(); // get latest sale price
                const txParams = {
                    from: PUBLIC_KEY,
                    to: params.contractAddress,
                    value: currentSalePrice,
                    nonce: nonce,
                    gas: 500000,
                    data: params.nftContract.methods.buy(tokenId).encodeABI()
                }
                params.topParams.web3.eth.sendTransaction(txParams, function (err, transactionHash) {
                    if (! err) 
                        console.log("Success! View at: https://ropsten.etherscan.io/tx/" + transactionHash);
                    
                    alert("Transaction Successful! View this transaction on the blockchain: https://ropsten.etherscan.io/tx/" + transactionHash)
                });
            } else {
                console.log("ID: " + tokenId + " is not for sale.")
            }
        }
    }

    const handleMessageChange = (msg) => { // console.log(msg)
        setMessage(msg);
    }
    const handleColorChange = (clr) => { // console.log(clr)
        setColor(clr);
    }
    const handleImageChange = (img) => { // console.log(clr)
        setImage(img);
    }
    const handleAudioChange = (aud) => {
        // console.log(clr)
        // setAudio(aud);
        // playSound(aud)
        Aud = new Audio(aud)
        Aud.play()
    }
    const handleBidChange = (bidP) => { // console.log(clr)
        setBid(bidP);
    }

    const handleSellChange = (sellP) => { // console.log(clr)
        setSellPrice(sellP);
    }

    return (
        <>
            <Modal  isOpen={params.modalIsOpen}
                    appElement={document.getElementById('app')}>
                <div className="modal-top-container">
                  <div className='title-modal'>
                    ID: {params.focusedMinute}
                  </div>
                  <button class="button-secondary" onClick={setModalIsOpenToFalse}>
                    Close
                  </button>
                </div>
                <div className='header-text'>
                    {timeEpochToDate(new Date(params.focusedMinute*1000))}
                </div>
                <div style={
                  {
                    id: "display_" + params.focusedMinute,
                    height: 300,
                    width: 300,
                    borderRadius: 10,
                    fontSize: 8,
                    backgroundColor: color,
                    backgroundImage: "url('" + image + "')",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderColor: "#BE88FF",
                    borderWidth: 1,
                    borderStyle: "solid",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    fontSize: 16,
                    overflow: "hidden"
                  }
                }>
                    {message}
                </div>
                <div className='header-text'>
                  {owner}
                </div>
                <br></br>
                <div className='modal-container'>
                  <div class="modal-elem modal-elem-owner">
                    <div className='title-modal-second'>
                      Owner Access
                    </div>
                    <div className='header-text'>
                      Message:
                    </div>
                    <input 
                      maxlength="280"
                      disabled={timeIsOwned && !timeIsAccountOwned}
                      className="modal-input"
                      type="text"
                      value={message}
                      onChange={e => handleMessageChange(e.target.value)}/>

                    <div className='header-text'>
                      Epoch Color:
                    </div>
                    <input 
                      maxlength="280"
                      disabled={timeIsOwned && !timeIsAccountOwned}
                      className="modal-input"
                      type="text"
                      value={color}
                      onChange={e => handleColorChange(e.target.value)}/>
                    <div className='header-text'>
                      Image URL:
                    </div>
                    <input 
                      maxlength="2048"
                      disabled={timeIsOwned && !timeIsAccountOwned}
                      className="modal-input"
                      type="text"
                      value={image}
                      onChange={e => handleImageChange(e.target.value)}/>
                    <div className='header-text'>
                      Audio URL:
                    </div>
                    <input 
                      maxlength="2048"
                      disabled={timeIsOwned && !timeIsAccountOwned}
                      className="modal-input"
                      type="text"
                      value={audio}
                      onChange={e => handleAudioChange(e.target.value)}/>
                    <button 
                      className='button-primary-modal'
                      //disable button if user is NOT the owner of this pixel
                      disabled={!timeIsAccountOwned}
                      onClick={modifyTime}>Modify TIME
                    </button>
                    <hr className='hero-header'/>
                    <div className='header-text'>
                      Sell Price:
                    </div>
                    <input 
                      disabled={!timeIsAccountOwned}
                      className="modal-input"
                      type="text"
                      value={sellPrice}
                      onChange={e => handleSellChange(e.target.value)}/>
                    <button 
                      className='button-primary-modal'
                      //disable button if user is NOT the owner of this pixel
                      disabled={!timeIsAccountOwned}
                      onClick={sellTime}>
                        Sell TIME
                    </button>
                  </div>
                  <br></br>

                  <div class="modal-elem">
                    <div className='title-modal-second'>
                      Purchase Options
                    </div>

                    <div>
                      <div className='header-text'>
                        Minting Price (<i>gas fee not included</i>)
                      </div>
                      <input 
                        disabled 
                        className="modal-input"
                        value={params.currentMintPrice}
                        type="number"/>
                      <button 
                        className='button-primary-modal button-primary-modal-buy'
                        //disable button if user IS the owner of this pixel OR if time is in the future
                        disabled={timeIsOwned || !timeIsPast}
                        onClick={mintTime}>Mint TIME
                      </button>
                    </div>

                    <br></br>

                    <div>
                      <div className='header-text'>
                        Maximum Bid (<i>only applicable to future epochs</i>)
                      </div>
                      <input 
                        className="modal-input"
                        value={bid}
                        min={params.currentMintPrice}
                        onChange={e => handleBidChange(e.target.value)}
                        type="number"/>
                      <div className='header-text'>
                        (<i>The account with the winning bid at this time will become the owner</i>)
                      </div>
                      <button 
                        className='button-primary-modal button-primary-modal-buy'
                        //disable button if time is in the past
                        disabled={timeIsPast || !web3Connected}
                        onClick={bidTime}>
                          Bid on TIME
                      </button>
                    </div>

                    <br></br>

                    <div>
                      <div className='header-text'>
                        Seller asking price (<i>gas fee not included</i>)
                      </div>
                      <input 
                        className="modal-input"
                        value={forSalePrice}
                        min={params.currentMintPrice}
                        onChange={e => handleBidChange(e.target.value)}
                        type="number"/>
                      <button 
                        className='button-primary-modal'
                        disabled={!timeIsForSale || timeIsAccountOwned}
                        onClick={buyTime}>
                          Buy TIME from seller
                      </button>
                    </div>
                  </div>
                </div>
                <div className='modal-container'>
                  <div className='modal-elem modal-elem-historical'>
                    <div className='title-modal-second'>
                      Historical Ownership
                    </div>
                  </div>
                </div>
            </Modal>
        </>
    );
}


export default TimeModal;
