import React, { useState, useEffect } from "react";
import "./TimeNow.css";
import dateFormat from "dateformat";

const TimeNow = (params) => {

  const tick = () => {
    //update state of time in different formats
    
    let minutes = Math.floor(Date.now() / 1000) - (Math.floor(Date.now() / 1000) % 60)
    params.setMinutes(minutes)
    let time = Date()
    params.setTime(time)
    let date = dateFormat(time , "dddd, mmmm dS, yyyy, h:MM:ss TT")
    params.setDate(date)
    // console.log(focusedSecond)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, 1000);
    return () => clearInterval(interval);
  }, [params.minutes]);

  const scrollToNow = () => {
    console.log(params.minutes)
    params.setScrollToMinute(params.minutes);
  }

  return( 
    <div className="header-text">
      {/* Date & Time:  */}
      {params.date}
      <br></br>
      ID: {params.minutes}
      <br></br>
      <button className="button-primary" onClick={scrollToNow}>View</button>
    </div>
  )
}

export default TimeNow;
