import React, {forwardRef, useEffect} from 'react';
import { FixedSizeGrid as Grid } from "react-window";
import './TimeGrid.css';



const gridRef = React.createRef();

function scrollGrid(column, row){
  // console.log(gridRef.current)
  gridRef.current.scrollToItem({
    align: "start",
    columnIndex: column,
    rowIndex: row
  });
}

const TimeGrid = (params) => {

  console.log('grid reset')
  var GUTTER_SIZE = 5*params.gridScale;
  var COLUMN_WIDTH = 50*params.gridScale;
  var ROW_HEIGHT = 50*params.gridScale;
  const WINDOW_WIDTH = window.innerWidth;
  var WINDOW_HEIGHT = window.innerHeight-250;
  const ROW_COUNT = 1440 //minutes
  //86400 //seconds in a day
  let COLUMN_COUNT = 100000
  //319565 //max columns with seconds

  // scale+=0.1
  // console.log(scale)
  var Aud = new Audio()
  Aud.id = "grid_audio"

  // console.log(params)
  // let checkedIDArr = []

  //wait for grid to render
  useEffect(() => {
    if (params.scrollToMinute !== null){
      //calculate focused grid position from seconds
      console.log(params.scrollToMinute)
      let column = Math.floor(params.scrollToMinute/ (ROW_COUNT*60))
      let row = (params.scrollToMinute) % (ROW_COUNT*60) / 60
      console.log(row)
      //scroll to focused second
      scrollGrid(column, row)
      //set scroll to null after grid is moved
      params.setScrollToMinute(null);
    };
  },[params.scrollToMinute]);

  //open modal with selected grid item
  const setModalIsOpenToTrue =(ID)=>{
    params.setModalIsOpen(true)
    //set focused second
    let intID = parseInt(ID)
    params.setFocusedMinute(intID)
  }

  const hoverOn =(ID)=>{
    // let pixel = document.getElementById(ID)
    // pixel.style.opacity = "0.5";
    Aud.pause()
    let intID = parseInt(ID)
    params.setFocusedMinute(intID)
    if (params.allPixelDetails[ID] !== undefined){
      if (params.allPixelDetails[ID].details.audio !== undefined){
        Aud.src = params.allPixelDetails[ID].details.audio
        Aud.play()
      }
    }
  }

  // const hoverOff =(ID)=>{
  //   // if (params.allPixelDetails[ID] !== undefined){
  //   //   if (params.allPixelDetails[ID].details.audio !== undefined){
  //       Aud.pause()
  //   //   }
  //   // }
  // }

  const readCachedURI = (ID) => {
    let jsonURI
    // console.log(params.allPixelDetails[ID].details)
    jsonURI = params.allPixelDetails[ID].details
    // console.log(jsonURI)
    return jsonURI
  }

  const Cell = ({ columnIndex, rowIndex, style}) => {
    let now = params.minutes
    let ID = (rowIndex*60)+(ROW_COUNT*columnIndex*60)
    let Status = ""
    let bgColor = ""
    let outColor = ""
    let outSize = 2
    let Opacity = "0.8"
    // let innerText = rowIndex+(ROW_COUNT*columnIndex) //seconds
    let innerText //= ID //minutes
    let bgImage = ""

    if (ID < now) {
      Status = "Available"
      bgColor = "#606368" 
      outColor = "#1E1E1E"      
    }
    if (ID == now) {
      Status = "Now"
      bgColor = "#606368" 
      outColor = "#BEC1C5"
    }
    if (ID > now) {
      Status = "Unavailable"
      bgColor = "#3C4042" 
      outColor = "#1E1E1E"
    }
    //if ID is in the array of owned NFTs of the connected wallet, add outline
    if (params.ownedPixels.includes(ID)) {
      console.log('found owned')
      Status = "Owned"
      bgColor = "#606368" 
      outColor = "#EA7FFB"
    }
    //hover effect
    if (ID == params.focusedMinute) {
      Opacity = "1.0"
      if (params.gridScale >= 1){
        innerText = ID
      }
    }

    //if ID is in the array of ALL owned NFTs of any account, fill with user defined features
    if (params.allPixelDetails[ID] !== undefined) {
      // console.log(params.allOwnedPixels)
      // console.log(params)
      let jsonURI
    
      // console.log('Reading locally cached URI details for: '+ID)
      jsonURI = readCachedURI(ID)
      if (jsonURI.message !== undefined){
        innerText = jsonURI.message
      }

      if (jsonURI.color !== undefined){
        bgColor = jsonURI.color
      }

      if (jsonURI.image !== undefined){
        bgImage = jsonURI.image
      }
    }



    return(
      <div
        className={["GridItem", Status].join(' ')}
        style={{
          ...style,
          left: style.left + GUTTER_SIZE,
          top: style.top + GUTTER_SIZE,
          width: style.width - GUTTER_SIZE,
          height: style.height - GUTTER_SIZE,
          borderRadius: 5,
          borderStyle: "solid",
          borderColor: outColor,
          borderWidth: outSize,
          fontSize: 8,
          backgroundColor: bgColor,
          backgroundImage: "url('"+bgImage+"')",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          cursor: "pointer",
          opacity: Opacity,
          color: "#202124",
          fontWeight: "bold",
          overflow: "hidden"
        }}
        id = {ID}
        // onClick={(e) => handleSelection(e)}
        onClick={(e) => setModalIsOpenToTrue(e.target.id)}
        onMouseEnter={(e) => hoverOn(e.target.id)}
      >
        {innerText}
      </div>
    )
  }

  return(
    <>
      <Grid
        ref={gridRef} 
        className={"Grid"}
        columnCount={COLUMN_COUNT}
        columnWidth={COLUMN_WIDTH + GUTTER_SIZE}
        height={WINDOW_HEIGHT}
        innerElementType={innerElementType}
        rowCount={ROW_COUNT}
        rowHeight={ROW_HEIGHT + GUTTER_SIZE}
        width={WINDOW_WIDTH}
      >
        {Cell}
      </Grid>
      {/* div below is for scroll performance per: https://github.com/bvaughn/react-window/issues/455 */}
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          pointerEvents: "none",
          backgroundColor: "rgba(0, 0, 0, 0)"
        }}
      />
    </>
  )
}

const innerElementType = forwardRef(({ style, GUTTER_SIZE, ...rest}, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      paddingLeft: GUTTER_SIZE,
      paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
));

export default TimeGrid;