import React, {useState, useEffect} from 'react';
import DateTimePicker from 'react-datetime-picker';
import './DTPicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'

//Date & Time Picker Navigation
const DTPicker = (params) => {
    const [value, setValue] = useState(new Date());
    const [zoomInBool, setZoomInBool] = useState(false);
    const [zoomOutBool, setZoomOutBool] = useState(false);
    const [goBool, setGoBool] = useState(true);
    const [epochId, setEpochId] = useState();

    // const tick = () => {
    //   //update state of time in different formats
    //   let minutes = Math.floor(Date.now()/60000)
    //   params.setMinutes(minutes)
    //   let time = Date()
    //   setValue(time)
    //   params.setTime(time)
    //   //let date = dateFormat(time , "dddd, mmmm dS, yyyy, h:MM:ss TT")
    //   params.setDate(date)
    //   // console.log(focusedSecond)
    // }
  
    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     tick();
    //   }, 1000);
    //   return () => clearInterval(interval);
    // }, [params.minutes]);
  
    // const scrollToNow = () => {
    //   params.setScrollToMinute(params.minutes);
    // }

    const handleEpochIdChange = (epoch) =>{
      // console.log(clr)
      setEpochId(epoch);
      setGoBool(false);
    }

    const scrollToEpoch = () => {
      params.setScrollToMinute(epochId);
      params.setFocusedMinute(epochId);
      setEpochId("");

      setGoBool(true);
    }
  
    const  scrollToDate = (date) => {
        console.log(date)
        setValue(date)
        let seconds = Math.floor(Date.parse(date)/1000) //convert from date to epoch seconds
        let minutes = seconds - (Math.floor(Date.now() / 1000) % 60) //convert to minutes
        console.log(minutes)
        params.setScrollToMinute(minutes) //update scroll To param, calls grid to move
    }

    const zoomIn = () => {
      console.log('zoom in')
      console.log(params.gridScale+0.2)
      params.setGridScale(params.gridScale + 0.2)
      params.setScrollToMinute(params.focusedMinute)
      if (params.gridScale >= 1.8){
        setZoomInBool(true)
        console.log('setting zoom in true')
      }
      console.log(zoomInBool)
      setZoomOutBool(false)
    }
    const zoomOut = () => {
      console.log('zoom out')
      console.log(params.gridScale-0.2)
      params.setGridScale(params.gridScale - 0.2)
      params.setScrollToMinute(params.focusedMinute)
      if (params.gridScale <= 0.6){
        setZoomOutBool(true)
      } 
      setZoomInBool(false)
    }
  
    return (
      <div>
        {/* <input type="datetime-local" 
          id="date-time-display"
          name="date-time-display" value={value}
          min="1970-01-01T00:00" max="2999-12-31T23:59"
          step="900"
          onChange={(value) => scrollToDate(value)}>
       </input> */}
        <DateTimePicker
          onChange={(value) => scrollToDate(value)}
          value={value}
          maxDetail="minute"
          disableClock={true}
          clearIcon={null}
        />
        <hr className='hero-header'/>
        <div className='header-browse-bottom-container'>
          <div className='header-browse-bottom-content'>
            <input id="browse-by-epoch" className="header-input" placeholder="Time ID" value={epochId} type="text"
            min="0" max="143999999"
            onChange={e => handleEpochIdChange(e.target.value)}/>
            <button disabled={goBool} className="button-primary" onClick={() => scrollToEpoch()}>
              Go
            </button>
          </div>
          <div className='header-browse-bottom-content'>
            <button disabled={zoomOutBool} className="button-secondary" onClick={zoomOut}>
              <FontAwesomeIcon size="lg" icon={faMagnifyingGlassMinus} />
            </button>
            <button disabled={zoomInBool} className="button-secondary" onClick={zoomIn}>
              <FontAwesomeIcon size="lg" icon={faMagnifyingGlassPlus} />
            </button>
          </div>
        </div>
      </div>
    );
  }

export default DTPicker;