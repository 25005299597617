import React, {useState, useEffect} from 'react';
import dateFormat from "dateformat";
import TimeNow from './TimeNow';
import DTPicker from './DTPicker'
import TimeModal from './TimeModal';
import TimeGrid from './TimeGrid';
import contract from './contracts/Time.json';
import './TopLevel.css';
// import pixels from './cache/pixels.json'
import Web3 from "web3";
// import {writeJsonFile} from 'write-json-file';
// var fs = require('browserify-fs');


function TopLevel(topParams) {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [allPixelDetails, setAllPixelDetails] = useState([])
    const [time, setTime] = useState(Date())
    const [date, setDate] = useState(dateFormat(time, "dddd, mmmm dS, yyyy, h:MM:ss TT"))
    const [minutes, setMinutes] = useState(Math.floor(Date.now() / 1000) - (Math.floor(Date.now() / 1000) % 60))
    const [scrollToMinute, setScrollToMinute] = useState(null)
    const [focusedMinute, setFocusedMinute] = useState(null)
    const [contractAddress, setContractAddress] = useState("0x5FbDB2315678afecb367f032d93F642f64180aa3")
    const [nftContract, setNftContract] = useState(null)
    const [ownedPixels, setOwnedPixels] = useState([])
    const [gridScale, setGridScale] = useState(1)
    const [currentMintPrice, setCurrentMintPrice] = useState(0)

    const connectedAccount = () => {
        // call when account has been detected to have connected
        // set state variables for the connected account
        // web3: topParams.web3
        // account: topParams.account
        console.log(topParams)

        // set NFT Contract address
        console.log({contractAddress}.contractAddress)
        let tempNftContract = new topParams.web3.eth.Contract(contract.abi, {contractAddress}.contractAddress)
        setNftContract(tempNftContract)
        checkOwnership(tempNftContract);
        updateLocalCache(tempNftContract)
    }

    const setStateFromServerCache = (data) => {
        setAllPixelDetails(data.detailList)
        console.log('State of pixels loaded from server cache')
    }

    async function loadServerCache() {
        console.log('trying to fetch cached data on pixels')
        fetch("/read", {method: 'get'}).then((res) => res.json()).then((data) => setStateFromServerCache(data));
    }

    async function updateLocalCache(NFTContract) {
        console.log('attempting to update local cache from blockchain')

        // if connected to web3, read latest from blockchain
        if (topParams.web3 != null && NFTContract != null) {
            console.log('found web3 provider and NFT Contract')
            // query the blockchain for all known owned pixel IDs
            // FYI - it is not currently possible to query the blockchain
            // for all IDs of a known NFT contract (if IDs are not sequential)
            // This is a work around.
            // console.log(allOwnedPixels.length)
            // CHANGE THIS TO CHECK TRANSFER EVENTS - BELOW IS NOT SAFE
            // Ref this post: https://stackoverflow.com/questions/69302924/erc-721-how-to-get-all-token-ids
            for (let i = 0; i < allPixelDetails.length; i++) {
                let ID = allPixelDetails[i]["ID"]
                console.log(i)
                console.log('checking blockchain for details of token ID: ' + ID)
                let strURI = await NFTContract.methods.tokenURI(ID).call(function (err, URI) {
                    if (err) {
                        console.log("ERROR reading URI")
                    }
                    if (! err) {
                        console.log(URI)
                    }
                });
                let jsonURI = JSON.parse(strURI)
                let liveDetails = JSON.stringify(jsonURI)
                let cachedDetails = JSON.stringify(allPixelDetails[i]["details"])

                console.log(liveDetails)
                console.log(cachedDetails)
                console.log(liveDetails == cachedDetails)

                // check if description of blockchain details match cached details
                // if yes, do nothing
                if (liveDetails !== cachedDetails) { // if they don't match, overwrite local details (allOwnedPixels and allPixelDetails) with blockchain data
                    allPixelDetails[i]["details"] = JSON.parse(liveDetails)
                    console.log(allPixelDetails[i]["details"])
                }
            }
            console.log(allPixelDetails)
            // Update cached server details with local details (allOwnedPixels and allPixelDetails)
            writeServerCache()

            // set mint price
            var currentPrice = await NFTContract.methods.currentPrice().call()
            setCurrentMintPrice(currentPrice)
        }
    }

    // write changes of local allOwnedPixels, allPixelDetails states to server cache
    async function writeServerCache() {
        console.log('writing to server cache')
        // console.log(allOwnedPixels)
        console.log(allPixelDetails)
        fetch('/write', {
            method: 'post',
            body: JSON.stringify({allPixelDetails})
        });
    }

    async function checkOwnership(NFTContract) { // check which TIME IDs are owned by connected account
        console.log(topParams.account)
        console.log(NFTContract)

        if (topParams.account != 'Not Connected') {
            let balance = await NFTContract.methods.balanceOf(topParams.account).call();
            // console.log(balance)
            let IDs = [];
            for (let i = 0; i < balance; i++) {
                let strID = await NFTContract.methods.tokenOfOwnerByIndex(topParams.account, i).call();
                IDs[i] = parseInt(strID);
            }
            // set owned IDs state variable
            setOwnedPixels([... IDs])
            console.log({ownedPixels})
        }
    }

    useEffect(() => {
        setScrollToMinute(minutes)
        setFocusedMinute(minutes)
        loadServerCache()
    }, []);

    // called when wallet/account is successfully connected
    useEffect(() => {
        if (topParams.account != 'Not Connected') {
            // account is connected
            // check and set ownership of account connected
            connectedAccount();
        }
    }, [topParams.account])

    return (
        <div>
            <div className="time-header">
                <div className="time-header-elem">
                    <div className="title">Browse Time</div>
                    <DTPicker setScrollToMinute={setScrollToMinute}
                        setMinutes={setMinutes}
                        setDate={setDate}
                        setTime={setTime}
                        minutes={minutes}
                        time={time}
                        date={date}
                        setGridScale={setGridScale}
                        gridScale={gridScale}
                        focusedMinute={focusedMinute}
                        setFocusedMinute={setFocusedMinute}/>
                </div>
                <div className="time-header-elem">
                    <div className="title">Current Time</div>
                    <TimeNow time={time}
                        date={date}
                        minutes={minutes}
                        setScrollToMinute={setScrollToMinute}
                        setDate={setDate}
                        setTime={setTime}
                        setMinutes={setMinutes}/>
                </div>
                <div className="time-header-elem">
                    <div className="title">Account</div>
                    <div className="header-text">
                        {
                        topParams.account.toLowerCase()
                    } </div>
                    <button type="primary" className="button-primary"
                        onClick={
                            () => topParams.connectClick()
                    }>
                        Connect
                    </button>
                </div>
            </div>
            <TimeGrid gridScale={gridScale}
                minutes={minutes}
                scrollToMinute={scrollToMinute}
                setScrollToMinute={setScrollToMinute}
                focusedMinute={focusedMinute}
                setFocusedMinute={setFocusedMinute}
                setModalIsOpen={setModalIsOpen}
                ownedPixels={ownedPixels}
                // topParams = {topParams}
                // contractAddress = {contractAddress}
                // nftContract = {nftContract}
                allPixelDetails={allPixelDetails}/>
            <TimeModal modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                focusedMinute={focusedMinute}
                topParams={topParams}
                ownedPixels={ownedPixels}
                contractAddress={contractAddress}
                nftContract={nftContract}
                minutes={minutes}
                allPixelDetails={allPixelDetails}
                setAllPixelDetails={setAllPixelDetails}
                writeServerCache={writeServerCache}
                checkOwnership={checkOwnership}
                currentMintPrice={currentMintPrice}/>
        </div>
    );
}

export default TopLevel;
