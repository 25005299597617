import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

// function getLibrary(provider) {
//   const library = new Web3Provider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

ReactDOM.render(
  <React.StrictMode>
    {/* <Web3ReactProvider getLibrary={getLibrary}> */}
      <script src="https://kit.fontawesome.com/c7670b1530.js" crossOrigin="anonymous"></script>
      <Protect sha512='6CC5D0523F55F9F923798463B4DEB938CFB637243B174F6DE2EB11398009A8A44CFC178C75E3093F4587120D75477FF8EEC791C4C199EDBA3A7C128CB4A7041D'>
        <App />
      </Protect>
    {/* </Web3ReactProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
